import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';
import Cdi from '../../components/Cdi';

export default function LeCdi() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collègé Jules Verne - Le CDI"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <Cdi />
      </Layout>
    </AnimationRevealPage>
  );
}
